import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { AvailabilityLabel } from '@/components/ProductDetailsPage/AvailabilityLabel'
import type { AvailabilityObject } from '@/components/ProductDetailsPage/DetailsTable/DetailsTable.utils'
import { ESTIMATED_TABLE_HEIGHT, MAX_ROWS_TO_SHOW } from '@/constants'
import type { components } from '@/types/schema.generated'

type DetailsTableProps = {
  rows:
    | components['schemas']['TechnicalDataTableRows']
    | components['schemas']['VariantsTableRows']
  columns: components['schemas']['TableHeaders']
  isDesktop: boolean
} & QaHook

export const DetailsTable = (props: DetailsTableProps) => {
  const { columns, rows, isDesktop, dataCy } = props
  const isNotDesktop = !isDesktop

  const hasAutoHeight = rows?.length <= MAX_ROWS_TO_SHOW || isNotDesktop

  return (
    <Box
      sx={{
        height: !hasAutoHeight ? ESTIMATED_TABLE_HEIGHT : 'auto',
        width: '100%',
        overflowX: 'scroll',
      }}
    >
      <Table data-cy={dataCy}>
        <TableHead>
          <TableRow>
            {columns?.map((column) => {
              return <TableCell key={column.key}>{column.name}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{
                '&:last-child': {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              {columns?.map((column, index) => {
                return Array.isArray(row[column.key as string]) ? (
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      {(row[column.key!] as AvailabilityObject[]).map((availabilityObject) => {
                        return (
                          <AvailabilityLabel
                            key={availabilityObject.iconLetter}
                            labelLetter={availabilityObject.iconLetter}
                            data-cy={`${dataCy}-availability-label`}
                          />
                        )
                      })}
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell key={index}>
                    <Typography variant="body3">
                      {row[column.key as string] as string}
                    </Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
